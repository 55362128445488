<template>
  <div class="c-container page">
    <span class="text-h5 font-weight-medium" v-text="article.title"></span>
    <div class="d-flex my-4 align-start">
      <article v-if="!article.type" v-html="article.content" style="flex: 1 1 50%"></article>
      <div class="flex-grow-1" v-if="article.type === 'tabs'" :key="article.title">
        <v-tabs fixed-tabs :vertical="$vuetify.breakpoint.mobile" background-color="transparent mb-5" style="width: 100%">
          <v-tab v-for="(tab, id) in article.content" :key="tab.title" class="justify-md-center justify-start"  @click="$router.push('#' + id)" :href="'#' + id">{{ tab.title }}</v-tab>
        </v-tabs>
        <v-tabs-items class="transparent" :value="currentTab">
          <v-tab-item v-for="(tab, id) in article.content" :key="tab.title" :value="id">
            <article v-html="tab.content" style="flex: 1 1 50%"></article>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div class="info--sidebar pl-6 d-none d-md-block">
        <v-sheet rounded class="overflow-hidden">
          <v-list dense>
            <v-list-item exact v-for="nav in navs" :key="nav.to" :to="nav.to" v-text="nav.text"></v-list-item>
          </v-list>
        </v-sheet>
        <ShareBlock />
      </div>
    </div>
  </div>
</template>

<script>
import ShareBlock from "@/components/ShareBlock.vue";

export default {
  name: "Info",
  components: {ShareBlock},

  metaInfo() {
    return {
      title: this.article?.title || 'Не найдено'
    }
  },

  computed: {
    currentTab: {
      set(val) {
        this.$router.push('#' + val)
      },
      get() {
        const str = this.$route.hash?.replace('#', '');
        return str ? Number(str) : 0;
      }
    },

    navs() {
      if (this.$route.path.startsWith('/info'))
      return [
        {
          to: '/info',
          text: 'Документы'
        },
        {
          to: '/info/priv',
          text: 'Общественные инициативы'
        },
        {
          to: '/info/readapt',
          text: 'Уход за деревьями'
        }
      ]
      return [
        {
          to: '/about',
          text: 'О ПРОЕКТЕ'
        },
        {
          to: '/about/join',
          text: 'КАК ПРИСОЕДИНИТЬСЯ?'
        },
        {
          to: '/about/privacy',
          text: 'РЕГЛАМЕНТ ОБРАБОТКИ ИНФОРМАЦИИ'
        },
        {
          to: '/about/help',
          text: 'ПОМОЩЬ'
        },
        {
          to: '/about/faq',
          text: 'ЧАСТЫЕ ВОПРОСЫ'
        },
        {
          to: '/about/contacts',
          text: 'КОНТАКТЫ'
        }
      ]
    },

    article() {
      if (this.$route.path.startsWith('/info')) {
        switch (this.$route.params.id) {
          case undefined: return {
            title: "Документы",
            content: `<p><b><a href="/docs/Приказ_Госстроя_РФ_от_15_12_1999_№_153_«Об_утверждении_Правил_создания.pdf" target="_blank">Приказ Госстроя РФ от 15.12.1999 № 153 «Об утверждении Правил создания, охраны и содержания зеленых насаждений в городах Российской Федерации»</a></b></p>
<p>Рекомендательный документ, цели которого регламентации основных
    вопросов ведения зеленого хозяйства.</p>
<br>

<p><b><a href="/docs/Закон 57-ПК.pdf" target="_blank">Закон Пермского края от 05.04.2022 № 57-ПК «Об озелененных территориях Пермского края» (принят ЗС ПК 17.03.2022)</a></b></p>
<p>Регулирует отношения в сфере сохранения и развития озелененных территорий Пермского края в границах городов краевого и районного значения с численностью населения не менее 20000 человек (численность населения определяется на основании данных Всероссийской переписи населения) и произрастающих на данных территориях зеленых насаждений, за исключением особо охраняемых природных территорий и территорий, занятых лесами.</p>
<br>

<p><b><a href="/docs/Решение_Пермской_городской_Думы_от_15_12_2020_№_277_«Об_утверждении.pdf" target="_blank">Решение Пермской городской Думы от 15.12.2020 № 277 «Об утверждении правил благоустройства территории города Перми»</a></b></p>
<p>Устанавливает общие требования к содержанию территории, объектам и элементам благоустройства, перечень мероприятий по благоустройству, порядок и периодичность их проведения, а также порядок контроля за соблюдением Правил.</p>
<br>

<p><b><a href="/docs/№30-01-02-1116pdf.pdf" target="_blank">Приказ Министерства природных ресурсов, лесного хозяйства и экологии Пермского края от 12.09.2022 № 30-01-02-1116 «Об утверждении Порядка установления охранного статуса особо ценных зеленых насаждений»</a></b></p>
<p>Утверждает порядок установления охранного статуса особо ценных зеленых насаждений.</p>
<br>

<p><b><a href="/docs/Постановление №787.pdf" target="_blank">Постановление администрации города Перми от 10.09.2024 №787 «Об утверждении состава комиссии по присвоению охранного статуса в отношении особо ценных зеленых насаждений, выявленных на земельных участках, находящихся в муниципальной собственности, или земельных участках, государственная собственность на которые не разграничена»</a></b></p>
<p>Устанавливает состав комиссии по присвоению охранного статуса в отношении особо ценных зеленых насаждений, выявленных на земельных участках, находящихся в муниципальной собственности, или земельных участках, государственная собственность на которые не разграничена.</p>
<br>

<p><b><a href="/docs/Постановление №715.pdf" target="_blank">Постановление администрации города Перми от 16.08.2023 № 715 «Об утверждении Регламента взаимодействия функциональных и территориальных органов администрации города Перми, муниципального казенного учреждения «Содержание объектов благоустройства» при организации ремонта и содержания объектов благоустройства на территории города Перми»</a></b></p>
<p>Регламент устанавливает порядок и формы взаимодействия между функциональными и территориальными органами администрации города Перми, муниципальными казенными учреждениями города Перми.</p>
<br>

<p><b><a href="/docs/Постановление №739.pdf" target="_blank">Постановление администрации города Перми от 02.09.2022 №739 «Об утверждении Регламента взаимодействия функциональных и территориальных органов администрации города Перми, муниципального казенного учреждения «Пермблагоустройство», муниципальных казенных учреждений по благоустройству районов города Перми и поселка Новые Ляды по отдельным вопросам озеленения территории города Перми»</a></b></p>
<p>Регламент разработан в целях совершенствования системы озеленения для обеспечения благоприятной окружающей среды и устанавливает порядок взаимодействия между функциональными и территориальными органами администрации города Перми, муниципальными казенными учреждениями города Перми.</p>
<br>

<p><b><a href="/docs/Постановление_Администрации_г_Перми_от_31_03_2023_№_254_«Об_утверждении.pdf" target="_blank">Постановление Администрации г. Перми от 31.03.2023 № 254 «Об утверждении порядка проведения работ по озеленению территорий общего пользования города Перми»</a></b></p>
<p>Устанавливает единые требования к проведению работ по озеленению территорий общего пользования, предусматривает гарантии на приживаемость посадочного материала; регулирует порядок организации работ по озеленению территорий общего пользования, порядок посадки зеленых насаждений на территории города Перми, включающий в себя требования к посадочному материалу и посадочным местам, требования к работам по посадке деревьев и кустарников, а также определяет ассортимент деревьев и кустарников, рекомендуемый для городского озеленения.</p>
<br>

<p><b><a href="/docs/Постановление_Администрации_г_Перми_от_29_04_2011_№_188_«Об_утверждении.pdf" target="_blank">Постановление Администрации г. Перми от 29.04.2011 № 188 «Об утверждении Перечня объектов озеленения общего пользования города Перми»</a></b></p>
<p>Устанавливает наименования объектов, территориальную принадлежность, их технические характеристики, а также определение правообладателей и балансодержателей земельных участков, на которых расположены объекты озеленения общего пользования, в том числе места массового отдыха жителей города Перми</p>
<br>

<p><b><a href="/docs/ГОСТ_Р_59370_2021_«Зеленые»_стандарты_Посадочный_материал_декоративных.pdf" target="_blank">ГОСТ Р 59370-2021 «Зеленые» стандарты. Посадочный материал декоративных растений</a></b></p>
<p>Определяет области применения, качественные требования на посадочный материал, его упаковку и маркировку при производстве и продаже.</p>
<br>

<p><b><a href="/docs/SP-476.pdf" target="_blank">СП 476.1325800.2020 «Территории городских и сельских поселений. Правила планировки, застройки и благоустройства жилых микрорайонов»</a></b></p>
<p> </p>
<br>

<p><b><a href="/docs/SP-42-13330-2016-Svod-pravil-Gradostroitelstvo.pdf" target="_blank">СП 42.13330.2011 «СНиП 2.07.01-89* Градостроительство. Планировка и застройка городских и сельских поселений»</a></b></p>
<p> </p>
<br>

<p><b><a href="/docs/SP-475.1325800.2020.-Svod-pravil.-Parki.-Pravila-gradostroit.pdf" target="_blank">СП 475.1325800.2020 Парки. Правила градостроительного проектирования и благоустройства. Правила градостроительного проектирования»</a></b></p>
<p> </p>
<br>`
          }
          case 'priv': return {
            title: "Общественные инициативы",
            content: `<p>
<ol>
    <li>Пермское краевое отделение общероссийской общественной организации «Всероссийское общество охраны природы» (<a href="https://vooppk.org" target="_blank">https://vooppk.org</a>, <a href="mailto:voop.pk@mail.ru" target="_blank">voop.pk@mail.ru</a>)</li>
    <li>Пермское городское отделение общероссийской общественной организации «Всероссийское общество охраны природы» (<a href="https://vk.com/club153646709" target="_blank">https://vk.com/club153646709</a>)</li>
    <li>Пермская региональная общественная организация «Ассоциация экологов Пермского края» (<a href="http://ecolog59.com" target="_blank">http://ecolog59.com</a>, <a href="mailto:kulakovasa@mail.ru" target="_blank">kulakovasa@mail.ru</a>)</li>
    <li>Пермское региональное отделение Общероссийской общественной организации «Центр экологической политики и культуры» (<a href="http://ecokultura59.ru" target="_blank">http://ecokultura59.ru</a>, <a href="mailto:ecokultura.perm@yandex.ru" target="_blank">ecokultura.perm@yandex.ru</a>)</li>
    <li>Муниципальное автономное учреждение дополнительного образования «Центр дополнительного образования для детей «Луч» г. Перми (<a href="http://www.luchperm.ru" target="_blank">http://www.luchperm.ru</a>, <a href="mailto:cdodd-lu4@yandex.ru" target="_blank">cdodd-lu4@yandex.ru</a>)</li>
    <li>Фонд культурного и природного наследия «Обвинская роза»</li>
    <li>Фонд защиты дикой природы «Следы человека в живой природе» (<a href="https://следычеловека.рф" target="_blank">https://следычеловека.рф</a>, <a href="mailto:fond@sledycheloveka.ru" target="_blank">fond@sledycheloveka.ru</a>)</li>
    <li>Фонд "Обитаемый Урал" (<a href="https://ecour.ru" target="_blank">https://ecour.ru</a>)</li>
    <li>АНЭО «Зелёная улица» (<a href="https://anozu.ru" target="_blank">https://anozu.ru</a>, <a href="mailto:anozu@bk.ru" target="_blank">anozu@bk.ru</a>)</li>
</ol>
</p>`
          };
          case 'readapt': return {
            title: 'Уход за деревьями',
            content: "..."
          };
        }
      } else {
        switch (this.$route.params.id) {
          case undefined: return {
            title: "О проекте",
            content: `<p>Портал “Зеленый город” — это самая полная и актуальная информация об объектах Зеленого фонда города – парках, скверах, городских лесах, особо охраняемых природных территориях и о деревьях на территории Перми. С помощью портала вы можете узнать о природе города, о различных характеристиках зеленых насаждений и об их санитарном состоянии (от тех, что растут вдоль улиц, до тех, что находятся в ландшафтных зонах парков).</p>
<p>Система, лежащая в основе портала, уже является рабочим инструментом для органов местного самоуправления города Перми при работе с зелеными насаждениями. Кроме объектов озеленения портал будет содержать данные о всех проводимых и планируемых работах с зелеными насаждениями, такими как посадка, обрезка, полив, подкормка и другими, а также расчетную оценку экологической пользы, которую приносит городу каждое конкретное дерево.</p>
<p>Портал запущен в тестовом режиме. Любой житель города может стать участником народной инвентаризации - добавлять деревья на карту города и дополнять фотографии к уже имеющимся деревьям, а также отмечать понравившиеся (любимые) деревья и следить за ними, оставлять замечания о неточностях в информации. После проверки добавляемых данных сотрудниками управления по экологии и природопользованию результаты народной инвентаризации будут добавляться в систему.</p>
<p>В течение ближайших месяцев система будет активно наполняться данными, в частности, будет добавлено большое количество объектов озеленения, расположенных вдоль улично-дорожной сети. Работы по улучшению полноты и достоверности данных будут продолжаться специалистами в течение всего 2023 года.</p>
<p>В перспективе система станет единым агрегатором и источником полной и достоверной информации о состоянии природной среды города Перми, в наполнении которой принимают участие и жители города.</p>`
          }
          case 'join': return {
            title: "Как присоединиться",
            content: `<p>Чтобы иметь возможность выполнять действия на портале, первым делом необходимо создать на портале свою учетную запись (аккаунт). При авторизации нажмите на кнопку «Зарегистрироваться», ознакомьтесь с правилами работы на портале и заполните небольшую анкету. После подтверждения регистрации с помощью письма, направленного на вашу электронную почту, будет создан ваш личный кабинет, и всё, вы готовы к работе!</p>
<p>Если у вас возникли сложности с регистрацией или вопросы по работе портала, обратитесь к нам за поддержкой по указанным контактам.</p>
`
          }
          case 'help': return {
            title: "Помощь и поддержка",
            content: "..."
          }
          case 'faq': return {
            title: "Частые вопросы",
            content: "..."
          }
          case 'privacy': return {
            title: 'Регламент обработки информации',
            type: 'tabs',
            content: [
              {
                title: 'Регламент работы с системой',
                content: `<p>1. Регламент обработки информации на портале «Зеленый город» (далее – Регламент) определяет порядок обработки данных, направляемых пользователями на портал.</p>
<p>2. В настоящем Регламенте используются следующие понятия и определения:</p>
<p>2.1. Портал «Зеленый город» – внешний доступный для пользователей интерфейс информационной системы “Озеленение Перми”, которая обеспечивает сбор, хранение, обработку, анализ и предоставление доступа к пространственным данным о Зеленом фонде города Перми различным категориям пользователей - сотрудникам органов власти и служб, организациям и жителям города.</p>
<p>2.2. Оператор портала – управление по экологии и природопользованию администрации города Перми.</p>
<p>2.3. Пользователь – физическое лицо (гражданин), прошедшее процедуру регистрации на портале «Зеленый город».</p>
<p>2.4. Объект пользователя – запись (данные) об объекте зеленого фонда города (например, о дереве), регистрируемый пользователем через предназначенный для этого интерфейс портала. Регистрируемые пользователями объекты поступают на дальнейшую обработку (проходят модерацию).</p>
<p>2.5. Обработка объекта пользователя – анализ, проверка (модерация) объекта, добавленного на портал пользователем, публикация на портале «Зеленый город» объекта пользователя, а также отклонение объектов пользователей или направление такого объекта на доработку.</p>
<p>2.6. Публикация объекта пользователя – размещение на портале «Зеленый город» в публичном доступе объекта, добавленного пользователем посредством интерфейса портала.</p>
<p>2.7. Органы власти – администрация города Перми, её подразделения и подведомственные организации.</p>
<p>2.8. Классификатор объектов зеленого фонда (классификатор) – перечень категорий и типов объектов, по которым группируются данные об объектах на портале «Зеленый город» в разделе Зеленый фонд.</p>
<p>2.9. Личный кабинет пользователя – персональный раздел портала «Зеленый город», предоставляющий пользователям возможности для работы с информацией, размещенной на портале «Зеленый город».</p>
<p>3. Портал «Зеленый город» создан в целях обеспечения оперативного взаимодействия пользователей и лиц, указанных в пункте 4 настоящего Регламента, по объектам зеленого фонда города Перми.</p>
<p>4. В обработке информации на портале «Зеленый город» принимают участие:</p>
<p>4.1. Сотрудники Управления по экологии и природопользованию администрации г. Перми, выполняющие функции администрирования портала и публикации информации на портале «Зеленый город».</p>
<p>4.2. Сотрудники муниципального казенного учреждения «Городское зеленое строительство» (МКУ «ГорЗеленСтрой»), выполняющие функции модерации данных на портале «Зеленый город».</p>
<p>4.3. Сотрудники других подразделений администрации г. Перми и сотрудники подрядных организаций.</p>
<p>5. Срок проверки (модерации) добавленного на портал пользователем нового объекта не должен превышать 10 рабочих дней со дня, следующего за днем добавления пользователем объекта на портал.</p>
<p>6. В целях анализа результатов обработки поступающих на портал данных от пользователей оператор портала вправе размещать на портале «Зеленый город» задания и проводить мероприятия, направленные на получение информации, подтверждающей и (или) опровергающей достоверность регистрируемых данных. Пользователи портала «Зеленый город» выполняют такие задания и участвуют в мероприятиях добровольно и на безвозмездной основе (далее – пользователь-волонтер).</p>`
              },
              {title: 'Обработка персональных данных', content: `<p>Портал “Зеленый город” не обрабатывает персональные данные пользователей, в том числе сбор,
запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
использование, передачу (распространение, предоставление, доступ) и другие виды операций.</p>`}
            ]
          };
          case 'contacts': return {
            title: 'Контакты',
            content: `<p><b>Управление по экологии и природопользованию</b></p>

<p>
  Адрес: 614000, Пермь, ул. Советская, 22<br>
  Телефон: (342) 210-99-91<br>
  Email: <a href="mailto:uep@perm.permkrai.ru">uep@perm.permkrai.ru</a><br>
  Часы работы: Пн-Чт: с 9:00 до 18:00, в Пт до 17:00, обед 13:00 - 13:48<br><br>
  Задачи: Организация обеспечения благоприятной окружающей среды, создание условий для обеспечения экологической безопасности и эффективного природопользования как основы жизни и деятельности населения, проживающего на территории города Перми.
</p>
<br>

<p><b>МКУ «Городское зеленое строительство» («ГорЗеленСтрой»)</b></p>

<p>
  Адрес: 614000, Пермь, ул. Николая Быстрых, 10<br>
  Телефон: (342) 208-85-07<br>
  Email: <a href="mailto:gzs@perm.permkrai.ru">gzs@perm.permkrai.ru</a><br>
  Группа "Вконтакте": <a href="https://vk.com/gorzelenstrojperm" target="_blank">https://vk.com/gorzelenstrojperm</a><br>
  Часы работы: Пн-Чт: с 9:00 до 18:00, в Пт до 17:00, обед 13:00 - 13:48<br><br>
  Задачи: Развитие системы озеленения для обеспечения благоприятной окружающей среды городского пространства на территории города Перми.
</p>
<br>


<p><b>МКУ «Пермское городское лесничество»</b></p>

<p>
  Адрес: 614015, Пермь, ул. Сибирская, 10, офис 96<br>
  Телефон: (342) 212-94-28<br>
  Email: <a href="mailto:mkupgl@mail.ru">mkupgl@mail.ru</a><br>
  Группа "Вконтакте": <a href="https://vk.com/permgorles&ZeroWidthSpace;" target="_blank">https://vk.com/permgorles&ZeroWidthSpace;</a><br>
  Часы работы: Пн-Чт: с 9:00 до 18:00, в Пт до 17:00, обед 13:00 - 13:48<br>
  <br>
  Задачи: Реализация программы использования, охраны защиты и воспроизводства городских лесов; благоустройство городских лесов; осуществление мониторинга за санитарным состоянием городских лесов; организация и проведение первичных мер по профилактике лесных пожаров; обеспечение соблюдения режимов охраны и пользования особо охраняемых природных территорий; уборка рассеянного мусора в местах отдыха и вдоль дорожно-тропиночной сети; выдача заключений о земельных участках относительно городских лесов.
</p>
<br>

<p><b>Департамент дорог и благоустройства</b></p>

<p>
  Адрес: 614000, Пермь, ул. Ленина, 25<br>
  Телефон: (342) 212-62-80<br>
  Email: <a href="mailto:ddb@gorodperm.ru">ddb@gorodperm.ru</a><br>
  Часы работы: Пн-Чт: с 9:00 до 18:00, в Пт до 17:00, обед 13:00 - 13:48<br>
  <br>
  Задачи: Организация дорожной деятельности в отношении автомобильных дорог местного значения в границах города Перми и обеспечение безопасности дорожного движения на них, организация дорожного движения, а также организация благоустройства территории города Перми в соответствии с правилами благоустройства территории города Перми, организация ритуальных услуг и содержание мест захоронения.
</p>
<br>

<p><b>МКУ «Пермблагоустройство»</b></p>

<p>
Адрес: 614000, г. Пермь, ул. Ленина, 25<br>
Телефон: (342) 212-26-26<br>
Email: <a href="mailto:mkublago@perm.permkrai.ru">mkublago@perm.permkrai.ru</a><br>
Часы работы: Пн-Чт: с 9:00 до 18:00, в Пт до 17:00, обед 13:00 - 13:48<br>
<br>
Задачи: Осуществление муниципальных работ и муниципальных функций по содержанию, строительству, реконструкции и капитальному ремонту объектов внешнего благоустройства на территории города Перми, для осуществления технического надзора и строительного контроля за выполнением работ.
</p>
<br>

<p><b>МКУ «Содержание объектов благоустройства»</b></p>

<p>
  Адрес: г. Пермь, ул. Пермская, 61<br>
  Телефон приемной: (342) 233-56-25<br>
  Email: <a href="mailto:mku-sob@mail.ru">mku-sob@mail.ru</a><br><br>
  Задачи: Содержание и ремонт автомобильных дорог, объектов озеленения, пешеходных мостиков и лестниц на территории общего пользования. Организация дорожной деятельности.
</p>
<br>

<p><b>Муниципальное автономное учреждение культуры «ПермьПарк» (МАУК «ПермьПарк»)</b></p>

<p>
Адрес: 614032, г. Пермь, ул. Маршала Рыбалко, 106<br>
Телефон: (342) 284-20-20<br>
Email: <a href="mailto:park-prm@kult.permkrai.ru">park-prm@kult.permkrai.ru</a><br>
Часы работы: Пн-Чт: с 9:00 до 18:00, в Пт до 17:00, обед 13:00 - 13:48<br>
<br>
Задачи: Основным видом деятельности является деятельность парков культуры и отдыха и тематических парков.<br>
В оперативном управлении МАУК «ПермьПарк» находится 4 парка города Перми:<br>
- Парк "Счастье есть" (Маршала Рыбалко, 106)<br>
- Парк культуры и отдыха им. А.П. Чехова (Репина, 20)<br>
- Сад им. В.Л. Миндовского (Мира, 9)<br>
- Сад им. Я.М. Свердлова - Райский сад (Красная площадь/ Соликамская).
</p>
<br>

<p><b>МБУ «Институт территориального планирования»</b></p>
<p>

Адрес: 614000, Пермь, Комсомольский проспект, 3<br>
Телефон: (342) 206-83-75, (342) 206-83-76<br>
Сайт: <a href="https://permgenplan.ru" target="_blank">https://permgenplan.ru</a><br>
Email: <a href="mailto:mku_itp@permgenplan.ru">mku_itp@permgenplan.ru</a><br>
Часы работы: Пн-Чт: с 9:00 до 18:00, в Пт до 17:00, обед 13:00 - 13:48<br>
<br>
Задачи: Обеспечение реализации предусмотренных действующим законодательством Российской Федерации полномочий органов местного самоуправления города Перми в сфере градостроительства и архитектуры.
</p>
<br>

<p><b>Администрации районов Перми</b></p>
<p>
Контакты: <a href="https://www.gorodperm.ru/structure/main/admin_citydistrict" target="_blank">https://www.gorodperm.ru/structure/main/admin_citydistrict</a><br>
<br>
Задачи: Обеспечение управления внутригородскими районами и осуществление исполнительно-распорядительных функций на соответствующих территориях.
</p>
<br>`
          }
        }
      }
      return {
        title: '404',
        content: "Статья не найдена"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-tab {
  word-break: break-word !important;
}

.info--sidebar {
  width: 340px;
  flex: 0 0 auto;

  .v-sheet {
    box-shadow: 0 0 8px rgb(0 0 0 / 8%);
    a {
      text-transform: uppercase;
      font-weight: 500;
      opacity: 0.7;
      font-size: 14px;
    }
    a.v-list-item--active {
      opacity: 1;
    }
    a:hover {
      text-decoration: none;
    }
  }
  position: sticky;
  top: 12px;
}

article {
  word-break: break-word;
  flex: 1 1 auto;

  ::v-deep h2 {
    font-weight: 500;
    margin: 8px 0;
  }
}
</style>
